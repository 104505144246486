<template>
    <section class="ActivityList" v-if="Activities && Activities.length > 0">
        <div class="container">
            <div class="flex-col text-center py-5 px-3 ActivityList_title">
                <div class="ActivityList_title-container">
                    <h1 class="text-3xl fw-500 baskerville" v-html="HeroTitle ? HeroTitle : 'Activities'"></h1>
                    <span class="text-base fw-300 op-7" v-html="HeroDescription ? HeroDescription : 'Morocco awaits!'"></span>
                </div>
            </div>
            <div class="ActivityList_list">
                <div class="grid mx-4 mb-5" >
                    <template v-if="Activities.length > 0">
                        <template v-for="(item, index) in Activities" :key="index">
                        <div class="col-xs-12 col-sm-6 col-md-4">
                            <ActivityCard
                                :name="item.attributes.Name"
                                :image="`${API.uploads}${item.attributes.Image.data ? item.attributes.Image.data.attributes.url : ''}`"
                                :slug="item.attributes.Slug"
                                :imageAlt="item.attributes.Image.data?.attributes?.alternativeText"
                            />
                        </div>
                        </template>
                    </template>
                    <template v-else>
                        <template v-for="(item, index) in Array(4)" :key="index">
                            <ActivityCardSkeleton />
                        </template>
                    </template>
                    <div class="col-12">
                        <div class="flex-col w-100 align-center justify-center">
                            <router-link to="/activities" class="button primary-border">View All</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import ActivityCard from "@/components/Activity/ActivityCard.vue"
import {API, getActivities} from "@/api.js"
import ActivityCardSkeleton from "./ActivityCardSkeleton.vue";
import { getConfig } from "@/data";
export default {
    props: {
      ActivitiesList: Array,
    },
    async created () {
        let conf = await getConfig();

        if(this.ActivitiesList){
            this.Activities = this.ActivitiesList;
        } else {
            let count = conf.activityShowCount ?? null;
            this.Activities = await getActivities(count);
        }
        
        this.HeroTitle = conf.Config.Activity_LIST 
            ? conf.Config.Activity_LIST.title ? conf.Config.Activity_LIST.title : '' : '';
        this.HeroDescription = conf.Config.Activity_LIST 
            ? conf.Config.Activity_LIST.description ? conf.Config.Activity_LIST.description : '' : '';
    },
    data () {
        return {
            API: API,
            Activities: [],
            HeroTitle: '',
            HeroDescription: ''
        }
    },
    components: {
        ActivityCard,
        ActivityCardSkeleton
    }
}
</script>
<style lang="scss" scoped>
    .ActivityList{
        &_title{
            display: flex;
            flex-direction: column;
            align-items: center;
            &-container{
                max-width: 400px;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
        &_scrollContainer{
            position: relative;
        }
        &_scroll{
            display: flex;
            flex-direction: row;
            overflow: auto;
            gap: 20px;
            scroll-behavior: smooth;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
                background: transparent;
            }
        }

        &_btn{
            position: absolute;
            top: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateY(-50%);
            border: 1px solid var(--color-primary);
            background: white;
            border-radius: 50px;
            color: var(--color-primary);
            z-index: 20;
            &:hover{
                background: var(--color-primary);
                color: white;
            }
            &.back{
                left: 10px;
                transform: translateY(-50%) translateX(-10%);
                @media(max-width: 500px){
                    transform: translateY(-50%) translateX(0%);
                }
            }
            &.next{
                right: 10px;
                transform: translateY(-50%) translateX(10%);
                @media(max-width: 500px){
                    transform: translateY(-50%) translateX(0%);
                }
            }
        }
    }
</style>
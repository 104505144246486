<template>
  <section class="WhyUsBlock background-topography">
    <div class="container">
      <div class="WhyUsBlock_container">
        <div class="WhyUsBlock_scroll">
          <template v-if="Slides">
            <template v-for="(slide, index) in Slides" :key="index">
              <div class="grid WhyUsBlock_scroll-block">
                <div class="col-sm-12 col-md-6 col-xs-12">
                  <div class="WhyUsBlock_text">
                    <div class="WhyUsBlock_text-container pb-4">
                      <span class="text-base op-5 fw-300 uppercase">{{slide.Eyebrow}}</span>
                      <span class="text-4xl my-3 baskerville">{{slide.Title}}</span>
                      <span class="text-xl fw-300 op-7 paragraph ParagraphContent">
                        {{slide.Description}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-xs-12">
                  <div class="WhyUsBlock_image">
                    <img :src="`${API.uploads}${slide.Image.data.attributes.url}`" :alt="slide.Image.data?.attributes?.alternativeText"/>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
        <div class="WhyUsBlock_move_button left" @click="scrollToPrevPage()">
          <span class="material-icons-outlined"> chevron_left </span>
        </div>
        <div class="WhyUsBlock_move_button right" @click="scrollToNextPage()">
          <span class="material-icons-outlined"> chevron_right </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {API, getWhyUs} from '@/api.js';

export default {
  async created () {
    this.GetSlides();
  },
  methods: {
    async GetSlides () { 
      let whyUs = await getWhyUs();
      this.Slides = whyUs.attributes.Slides;
      return this.Slides;
    },
    scrollToNextPage () { 
      let gallery_scroller = document.querySelector(".WhyUsBlock_scroll");
      let gallery_item_size = gallery_scroller.clientWidth;
      gallery_scroller.scrollBy(gallery_item_size, 0);
    },
    scrollToPrevPage () {
      let gallery_scroller = document.querySelector(".WhyUsBlock_scroll");
      let gallery_item_size = gallery_scroller.clientWidth;
      gallery_scroller.scrollBy(-gallery_item_size, 0);
    }
  },
  data () {
    return {
      Slides: [],
      API: API,
    }
  },
  setup() {
    
  },
  components:{
    
  }
}
</script>

<style lang="scss" scoped>

.WhyUsBlock{
    padding: 60px 40px;
    color: black;
    flex-shrink: 0;
    &_scroll{
        display: flex;
        flex-direction: row;
        overflow: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        &-block{
            scroll-snap-align: center;
            width:100%;
            flex-shrink: 0;
        }
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background: transparent;
        }
    }
    &_container{
        display: flex;
        flex-direction: row;
        position: relative;
        .paragraph{
            max-width: 400px;
        }
    }
    &_text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        &-container{
            display: flex;
            flex-direction: column;
            justify-content: center;

            margin: auto 0px;
            span{
                max-width: 450px;
            }
        }
    }
    &_image{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img{
            width: 100%;
            transition: 0.2s ease-in-out;
            height: 600px;
            object-fit: cover;
            @media(max-width: 768px){
                max-height: 400px;
            }
        }
    }
    &_move_button{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
        border: 1px solid var(--color-primary);
        color: var(--color-primary);
        backdrop-filter: blur(10px);
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        .material-icons-outlined, .material-icons{
            font-size: 30px;
        }
        &.left{
            left: 0;
            transform: translateY(-50%) translateX(-50%);
            &:hover{
                transform: translateY(-50%) translateX(-50%) scale(1.05);
            }
        }
        &.right{
            right: 0;
            transform: translateY(-50%) translateX(50%);
            &:hover{
                transform: translateY(-50%) translateX(50%) scale(1.05);
            }
        }
    }
}

</style>
<template>
    <div class="ContactButton" v-if="link">
        <a :href="link" target="_blank">
            <div class="ContactButton_button">
                <i class='bx bxl-whatsapp'></i>
            </div>
        </a>
    </div>
</template>

<script>
import { getContacts } from "@/data"

export default {
  async created () {
      let ContactInfo = await getContacts();
      this.link = ContactInfo.whatsappLink;
  },
  data () {
    return {
        link: null,
    }
  },
}
</script>

<style lang="scss" scoped>
    .ContactButton{
        position: fixed;
        bottom: 0px;
        left: 0px;
        padding: 20px;
        z-index: 50;
        &_button{
            width: 60px;
            height: 60px;
            padding: 20px;
            background: #075E54;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            border-radius: 100px;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            border: 1px solid rgba(0, 0, 0, 0.1);
            filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
            font-size: 28px;
            &:hover{
                background: #25D366;
            }
        }
    }
</style>

<template>
    <div class="ItineraryCard skeleton-background">
        <span class="ItineraryCard_location">
            <span class="text-xs">location</span>
        </span>
        <div class="flex-col gap-10">
            <span class="ItineraryCard_title skeleton-text"></span>
            <hr>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ItineraryCard',

}
</script>

<style lang="scss" scoped>
.ItineraryCard {
    width: 100%;
    aspect-ratio: 2.6 / 4;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: white;
    isolation: isolate;
    overflow: hidden;
    position: relative;
    transition: 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        .ItineraryCard_background {
            transform: scale(1.02);
        }
        hr {
            width: 0%;
            align-self: flex-end;
        }
        .ItineraryCard_time {
            max-height: 100px;
            opacity: 1;
            margin-top: 10px;
        }
    }

    &_location {
        position: absolute;
        top: 30px;
        left: 30px;
        z-index: 2;
        text-transform: uppercase;
        background: var(--skeleton-color-deg);
        color: transparent;
        padding: 5px 10px;
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 1px;
        max-width: calc(100% - 60px);
    }

    &_background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -2;
        width: 100%;
        height: 100%;
        transition: 0.4s ease-in-out;
        object-fit: cover;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.02) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        z-index: -1;
        pointer-events: none;
    }

    &_title {
        font-family: "Libre Baskerville", serif;
        width: 180px;
        height: 32px;
        margin-bottom: 5px;
    }
    hr {
        border: none;
        border-bottom: 2px solid var(--skeleton-color-deg);
        position: relative;
        width: 100%;
        transition: 0.6s ease-in-out;
    }
    &_description {
        display: flex;
        flex-direction: row;
        gap: 20px;
        font-size: 15px;
        text-transform: uppercase;
        * {
            font-family: "Inter", sans-serif;
        }
    }
    &_time {
        display: flex;
        flex-direction: row;
        gap: 20px;
        font-size: 12px;
        text-transform: uppercase;
        max-height: 0px;
        // overflow: hidden;
        transition: 0.4s ease-in-out;
        opacity: 0;
        &-wrapper {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
        }
    }
}
</style>
<template>
    <section class="ArticlesList">
        <div class="container">
            <div class="flex-col text-center py-5 px-3 ArticlesList_title">
                <div class="ArticlesList_title-container">
                    <h1 class="text-3xl fw-500 baskerville" v-html="HeroTitle ? HeroTitle : 'Our Journal'"></h1>
                    <span class="text-base fw-300 op-7" v-html="HeroDescription ? HeroDescription : `Discover what's interesting about our country`"></span>
                </div>
            </div>
            <div class="ArticlesList_grid">
                <div class="grid">
                    <template v-if="Articles">
                        <template v-for="(item, index) in Articles" :key="index">
                            <div class="col-xs-12 col-sm-6 col-md-4">
                                <ArticleCard
                                    cardStyle="minimal"
                                    :slug="item.attributes.Slug"
                                    :name="item.attributes.Title"
                                    :image="`${API.uploads}${item.attributes.Picture.data ? item.attributes.Picture.data.attributes.url : ''}`"
                                    :created_at="item.attributes.publishedAt"
                                />
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <template v-for="(item, index) in Array(3)" :key="index">
                            <div class="col-xs-12 col-sm-6 col-md-4">
                                <ArticleCardSkeleton cardStyle="minimal"/>
                            </div>
                        </template>
                    </template>
                    <div class="col-12">
                        <div class="flex-row flex-center gap-20">
                            <router-link to="/blog">
                                <button class="button primary">View More</button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { API, getArticles } from "@/api";
import { getConfig } from "@/data";
import ArticleCard from "./ArticleCard.vue"
import ArticleCardSkeleton from "./ArticleCardSkeleton.vue";
    export default {
    data() {
        return {
            API: API,
            Articles: null,
            HeroTitle: '',
            HeroDescription: ''
        };
    },
    async created() {
        let conf = await getConfig();
        if(conf.featuredarticles.data && conf.featuredarticles.data.length > 0){
            this.Articles = conf.featuredarticles.data;
        } else {
            this.Articles = await getArticles(3);
        }
        this.HeroTitle = conf.Config.JOURNAL_LIST 
            ? conf.Config.JOURNAL_LIST.title ? conf.Config.JOURNAL_LIST.title : '' : '';
        this.HeroDescription = conf.Config.JOURNAL_LIST 
            ? conf.Config.JOURNAL_LIST.description ? conf.Config.JOURNAL_LIST.description : '' : '';
    },
    components: { ArticleCard, ArticleCardSkeleton }
}
</script>

<style lang="scss" scoped>
    .ArticlesList{
        display: flex;
        flex-direction: column;
        background: var(--color-bg-fade-light);
        &_title{
            display: flex;
            flex-direction: column;
            align-items: center;
            &-container{
                max-width: 400px;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
        &_grid{
            padding: 0px 40px 40px 40px;
            @media(max-width: 700px){
                padding: 0px 1rem 40px 1rem;
            }
        }
    }
</style>
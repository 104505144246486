<template>
    <section class="FeedbackBlock">
        <div class="container">
            <div class="flex-col text-center py-5 px-3 FeedbackBlock_title">
                <div class="FeedbackBlock_title-container">
                    <h1 class="text-3xl fw-500 baskerville" v-html="HeroTitle ? HeroTitle : 'Customer reviews'"></h1>
                    <span class="text-base fw-300 op-7" v-html="HeroDescription ? HeroDescription : 'What our customers say about us'"></span>
                </div>
            </div>
            <div class="FeedbackBlock_grid">
                <div class="grid">
                    <template v-for="(item, index) in Feedbacks" :key="index">
                        <div class="col-md-6 col-sm-12 col-xs-12">
                            <div class="FeedbackReview">
                                <div class="flex-col gap-20">
                                    <div class="flex-col">
                                        <span class="text-base op-8">{{item.attributes.Name}}{{item.attributes.Country ? ' - '+item.attributes.Country : ''}}</span>
                                        <span class="text-sm op-5">{{item.attributes.itinerary.data ? item.attributes.itinerary.data.attributes.Name : 'Custom Trip'}}</span>
                                    </div>
                                    <span class="FeedbackReview_message text-base">
                                        {{item.attributes.Message}}
                                    </span>
                                </div>
                                <div class="flex-row space-between">
                                    <span class="text-sm fw-300 op-7">{{moment(item.attributes.createdAt).format('LL')}}</span>
                                    <ul class="FeedbackReview_stars">
                                        <li :class="{'color-star' : item.attributes.Rate >= 1 }"><i class='bx bxs-star'></i></li>
                                        <li :class="{'color-star' : item.attributes.Rate >= 2 }"><i class='bx bxs-star'></i></li>
                                        <li :class="{'color-star' : item.attributes.Rate >= 3 }"><i class='bx bxs-star'></i></li>
                                        <li :class="{'color-star' : item.attributes.Rate >= 4 }"><i class='bx bxs-star'></i></li>
                                        <li :class="{'color-star' : item.attributes.Rate >= 5 }"><i class='bx bxs-star'></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="col-12">
                        <div class="flex-row flex-center gap-20">
                            <button class="button primary" @click="loadMore" v-if="!endList">
                                <span v-if="!isLoading">Load More</span>
                                <span v-if="isLoading"><i class='bx bx-loader-alt bx-spin'></i></span>
                            </button>
                        </div>
                        <div class="info-wrapper square align-center p-3" v-if="endList && Feedbacks.length < 1">
                            <div class="info-message text-center text-base">
                                No reviews for now yet 😞 guess everyone is happy ?
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getFeedbacks } from '@/api'
import { getConfig } from '@/data';
import moment from 'moment'
export default {
  methods: {
    loadMore: async function () {
        this.page++;
        this.isLoading = true;
        let nitns = await getFeedbacks(this.page);
        if(nitns.length > 0){
            nitns.forEach(el => this.Feedbacks.push(el));
        } else {
            this.endList = true;
        }
        setTimeout(()=>{
            this.isLoading = false;
        })
    }
  },
  setup() {
        return {moment}
    },
  data () {
    return {
        Feedbacks: null,
        page: 1,
        isLoading: false,
        endList: false,

        HeroTitle: '',
        HeroDescription: ''
    }
  },
  async created () {
    this.Feedbacks = await getFeedbacks();

    let conf = await getConfig();
        
    this.HeroTitle = conf.Config.FEEDBACKS_LIST
        ? conf.Config.FEEDBACKS_LIST.title ? conf.Config.FEEDBACKS_LIST.title : '' : '';
    this.HeroDescription = conf.Config.FEEDBACKS_LIST
        ? conf.Config.FEEDBACKS_LIST.description ? conf.Config.FEEDBACKS_LIST.description : '' : '';
  },
    
}
</script>

<style lang="scss" scoped>
    .FeedbackBlock{
        display: flex;
        flex-direction: column;
        &_title{
            display: flex;
            flex-direction: column;
            align-items: center;
            &-container{
                max-width: 400px;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
        &_grid{
            padding: 0px 40px 40px 40px;
            @media(max-width: 700px){
                padding: 0px 1rem 40px 1rem;
            }
        }
    }

    .FeedbackReview{
        padding: 20px;
        background: var(--color-bg-fade-light);
        border-top: 1px solid var(--color-bg-fade);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        height: 100%;
        &_stars{
            display: flex;
            flex-direction: row;
            gap: 5px;
            list-style: none;
            color: var(--color-bg-fade);

        }
    }
    .color-star{
        color: var(--stars-colors);
    }
</style>
<template>
<div class="flex-col bg-white">
  <HeroBlock/>
  <WhyusBlock/>
  <ItinerariesList/>
  <CustomiseBlock/>
  <DestinationsList/>
  <FeedbackBlock/>
  <ActivitiesList/>
  <ArticlesList/>
  <FooterUi/>
</div>
</template>

<script>
import FooterUi from '@/components/FooterUi.vue';
import HeroBlock from '@/components/Blocks/HeroBlock.vue';
import WhyusBlock from '@/components/Blocks/WhyusBlock.vue';
import ItinerariesList from '@/components/Itinerary/ItinerariesList.vue';
import CustomiseBlock from '@/components/Blocks/CustomiseBlock.vue';
import DestinationsList from '@/components/Destination/DestinationsList.vue';
import FeedbackBlock from '@/components/Blocks/FeedbackBlock.vue';
import ArticlesList from '@/components/Article/ArticlesList.vue';
import ActivitiesList from '../components/Activity/ActivitiesList.vue';
import { getConfig } from '@/data';
import { appendMeta, clearMeta } from '../data';


export default {
  data () {
    return {
      seo: []
    }
  },
  name: 'HomeView',
  async created() {
    let config = await getConfig();
    this.seo = config.seo;
    if(!Array.isArray(this.seo))
      this.seo = [this.seo];
    appendMeta(this.seo);
  },
  beforeUnmount () {
    clearMeta(this.seo);
  },
  components: {
    FooterUi,
    HeroBlock,
    WhyusBlock,
    ItinerariesList,
    CustomiseBlock,
    DestinationsList,
    FeedbackBlock,
    ArticlesList,
    ActivitiesList
  }
}
</script>

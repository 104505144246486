<template>
    <section class="CustomiseBlock">
        <img :src="HeroImage ? HeroImage : require('@/assets/cover-customise.jpg')" role="presentation" class="CustomiseBlock_background">
        <div class="flex-col flex-center gap-10 text-center">
            <span class="uppercase text-sm color-primary" v-html="HeroEyebrow ? HeroEyebrow : 'GET IN TOUCH NOW'"></span>
            <span class="text-4xl baskerville" v-html="HeroTitle ? HeroTitle : 'Start your dream journey'"></span>
            <p class="paragraph text-sm fw-300 mt-2 op-7" v-html="HeroDescription ? HeroDescription : 'You can customise your trip to your liking and preferences,<br> Send an Enquiry'"></p>
            <div class="flex-row gap-20 mt-3">
                <!-- <router-link to="/activities" class="button primary-ghost">Discover activites</router-link> -->
                <router-link to="/enquire" class="button primary" v-html="HeroActionName ? HeroActionName : 'Make and inquiry'"></router-link>
            </div>
        </div>
    </section>
</template>

<script>
import { getConfig } from '@/data';

export default {
    async created () {
        let conf = await getConfig();
        this.HeroEyebrow = conf.Config.GET_IN_TOUCH_BLOCK 
            ? conf.Config.GET_IN_TOUCH_BLOCK.eyebrow ? conf.Config.GET_IN_TOUCH_BLOCK.eyebrow : '' : '';
        this.HeroTitle = conf.Config.GET_IN_TOUCH_BLOCK 
            ? conf.Config.GET_IN_TOUCH_BLOCK.title ? conf.Config.GET_IN_TOUCH_BLOCK.title : '' : '';
        this.HeroDescription = conf.Config.GET_IN_TOUCH_BLOCK 
            ? conf.Config.GET_IN_TOUCH_BLOCK.description ? conf.Config.GET_IN_TOUCH_BLOCK.description : '' : '';
        this.HeroActionName = conf.Config.GET_IN_TOUCH_BLOCK 
            ? conf.Config.GET_IN_TOUCH_BLOCK.action_name ? conf.Config.GET_IN_TOUCH_BLOCK.action_name : '' : '';
        this.HeroImage = conf.Config.GET_IN_TOUCH_BLOCK 
            ? conf.Config.GET_IN_TOUCH_BLOCK.background_image ? conf.Config.GET_IN_TOUCH_BLOCK.background_image : '' : '';
    },
    data () {
        return {
            HeroEyebrow: '',
            HeroTitle: '',
            HeroDescription: '',
            HeroActionName: '',
            HeroImage: null
        }
    },
}
</script>

<style lang="scss" scoped>
.CustomiseBlock{
    display: flex;
    flex-direction: column;
    padding: 80px 1rem;
    background: var(--color-background);
    color: white;
    position: relative;
    isolation: isolate;

    &_background{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        inset: 0;
        z-index: -1;
        opacity: 0.4;
        user-select: none;
        pointer-events: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}
</style>
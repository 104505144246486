<template>
  <div class="app" 
    x-data="{
      navbarOpen:false,
      hide: false,
      lastPos: $refs.app.scrollTop + 0,
      scroll() {
        if(this.lastPos > $refs.app.scrollTop || $refs.app.scrollTop < 100){
          this.hide = false;
        } else {
          this.hide = true;
        }
        this.lastPos = $refs.app.scrollTop;
      },
    }"
    x-on:scroll="scroll()"
    x-init="sections = document.querySelectorAll('section')"
    x-ref="app">
    <NavbarUi :position="$route.name == 'itinerary' ? 'fixed' :''" />
    <router-view v-slot="{ Component }" :key="$route.fullPath">
      <transition name="fade" mode="out-in">
        <component :is="Component"/>
      </transition>
    </router-view>
    <ContactButtonUi/>
    <LoadingUi :isHidden="isLoading"/>
  </div>
</template>

<script>
import NavbarUi from "./components/NavbarUi.vue"
import ContactButtonUi from "./components/ContactButtonUi.vue"
import { getConfiguration, getContactInfo } from "./api"
import { appendMeta, setConfig, setContacts} from './data'
import LoadingUi from "./components/LoadingUi.vue"
import { useHead } from '@unhead/vue'
export default {
  async created () {
    let conf = await getConfiguration();
    if(conf && conf.attributes && conf.attributes.Config && conf.attributes.Config.GOOGLE_SITE_VERIFICATION_META) {
      appendMeta([{meta: [conf.attributes.Config.GOOGLE_SITE_VERIFICATION_META]}]);
    }
    let GTAG_ID = ''
    if(conf && conf.attributes && conf.attributes.Config && conf.attributes.Config.GTAG_ID) {
      GTAG_ID = conf.attributes.Config.GTAG_ID;
      useHead({
        script:[
          {
            async: true,
            src: `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`
          }
        ]
      })
    }
    window['dataLayer'] = window['dataLayer'] || [];
    function gtag(){window['dataLayer'].push(arguments);}
    if(GTAG_ID){
      gtag('js', new Date());
      gtag('config', GTAG_ID);
    }
    let contacts = await getContactInfo();
    setConfig(conf.attributes);
    setContacts(contacts);
    setTimeout(()=>{
      this.isLoading = false;
    })
  },
  data () {
    return {
      isLoading: true
    }
  },
  components: {
    NavbarUi,
    ContactButtonUi,
    LoadingUi,
  }
}
  
</script>

<style lang="scss">
@import "@/sass/global.scss";
@import '@splidejs/vue-splide/css';
@import '@/sass/splide_overrides.scss';

#app{
  overflow: hidden;
}

.fade-enter-active {
  transition: all 0.3s ease;
}

.fade-leave-active {
  transition: all 0.1s ease;
}

.fade-enter-from{
  opacity: 0;
  transform: translateY(-20px);
}
.fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

</style>

<template>
    <section class="DestinationList" x-data>
        <div class="container">
            <div class="flex-col text-center py-5 px-3 DestinationList_title">
                <div class="DestinationList_title-container">
                    <h1 class="text-3xl fw-500 baskerville" v-html="HeroTitle ? HeroTitle : 'Where will you go next?'"></h1>
                    <span class="text-base fw-300 op-7" v-html="HeroDescription ? HeroDescription : 'Morocco awaits!'"></span>
                </div>
            </div>
            <div class="DestinationList_scrollContainer">
                <div class="DestinationList_scroll mx-4 mb-5" x-ref="scroll">
                    <template v-if="Destinations.length > 0">
                        <template v-for="(item, index) in Destinations" :key="index">
                            <DestinationCard
                                :name="item.attributes.Name"
                                :image="`${API.uploads}${item.attributes.Image.data ? item.attributes.Image.data.attributes.url : ''}`"
                                :alt="item.attributes.Image.data?.attributes?.alternativeText"
                            />
                        </template>
                    </template>
                    <template v-else>
                        <template v-for="(item, index) in Array(4)" :key="index">
                            <DestinationCardSkeleton />
                        </template>
                    </template>
                </div>
                <button class="DestinationList_btn back" 
                    x-on:click="$refs.scroll.scrollBy(-$refs.scroll.clientWidth, 0);">
                    <span class="material-icons-outlined">
                    chevron_left
                    </span>
                </button>
                <button class="DestinationList_btn next"
                    x-on:click="$refs.scroll.scrollBy($refs.scroll.clientWidth, 0);">
                    <span class="material-icons-outlined">
                    chevron_right
                    </span>
                </button>
            </div>
        </div>
    </section>
</template>
<script>
import DestinationCard from "@/components/Destination/DestinationCard.vue"
import {API, getDestinations} from "@/api.js"
import DestinationCardSkeleton from "./DestinationCardSkeleton.vue";
import { getConfig } from "@/data";
export default {
    async created () {
        this.Destinations = await getDestinations();
        
        let conf = await getConfig();

        this.HeroTitle = conf.Config.DESTINATIONS_LIST 
            ? conf.Config.DESTINATIONS_LIST.title ? conf.Config.DESTINATIONS_LIST.title : '' : '';
        this.HeroDescription = conf.Config.DESTINATIONS_LIST 
            ? conf.Config.DESTINATIONS_LIST.description ? conf.Config.DESTINATIONS_LIST.description : '' : '';
    },
    data () {
        return {
            API: API,
            Destinations: [],
            HeroTitle: '',
            HeroDescription: ''
        }
    },
    components: {
    DestinationCard,
    DestinationCardSkeleton
}
}
</script>
<style lang="scss" scoped>
    .DestinationList{
        &_title{
            display: flex;
            flex-direction: column;
            align-items: center;
            &-container{
                max-width: 400px;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
        &_scrollContainer{
            position: relative;
        }
        &_scroll{
            display: flex;
            flex-direction: row;
            overflow: auto;
            gap: 20px;
            scroll-behavior: smooth;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
                background: transparent;
            }
        }

        &_btn{
            position: absolute;
            top: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateY(-50%);
            border: 1px solid var(--color-primary);
            background: white;
            border-radius: 50px;
            color: var(--color-primary);
            z-index: 20;
            &:hover{
                background: var(--color-primary);
                color: white;
            }
            &.back{
                left: 10px;
                transform: translateY(-50%) translateX(-10%);
                @media(max-width: 500px){
                    transform: translateY(-50%) translateX(0%);
                }
            }
            &.next{
                right: 10px;
                transform: translateY(-50%) translateX(10%);
                @media(max-width: 500px){
                    transform: translateY(-50%) translateX(0%);
                }
            }
        }
    }
</style>
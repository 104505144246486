<template>
    <section class="ItineraryList">
        <div class="container">
            <div class="flex-col mx-3 text-center py-5 ItineraryList_title">
                <div class="ItineraryList_title-container">
                    <h1 class="text-3xl fw-500 baskerville" v-html="HeroTitle ? HeroTitle : 'Ready made or build <br> your own'"></h1>
                    <span class="text-base fw-300 op-7" v-html="HeroDescription ? HeroDescription : 'All our itineraries are tailor made, but here are some of our favourite routes and camp combinations.'"></span>
                </div>
            </div>
            <div class="flex-col mb-5 mx-3">
                <div class="grid">
                    <template v-if="Itineraries">
                        <template v-if="Itineraries.length > 0">
                            <template v-for="(item, index) in Itineraries" :key="index">
                                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                    <ItineraryCard 
                                        :cover="item.attributes.Image.data ? `${API.uploads}${item.attributes.Image.data.attributes.url}` : null"
                                        :alt="item.attributes.Image?.data?.attributes?.alternativeText"
                                        :title="item.attributes.Name"
                                        :price="item.attributes.Price ? item.attributes.Price.LowSeason || 0 : 0"
                                        :days="item.attributes.Days || 1"
                                        :locations="item.attributes.destinations ? item.attributes.destinations.data.map(item => item.attributes.Name) : []"
                                        :departure="item.attributes.Departure.data ? item.attributes.Departure.data.attributes.Name : null"
                                        :returnlocation="item.attributes.Return.data ? item.attributes.Return.data.attributes.Name : null"
                                        :redirectTo="`/itinerary/${item.attributes.Slug}`"
                                        />
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <div class="col-12 ItineraryList_empty">
                            <span class="material-icons-outlined op-3">
                                explore
                            </span>
                            <span class="text-xl fw-400 op-4 mt-3">No Itineraries Found</span>
                        </div>
                        </template>
                    </template>
                    <template v-else>
                        <template v-for="(item, index) in Array(6)" :key="index">
                            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <ItineraryCardSkeleton/>
                            </div>
                        </template>
                    </template>
                    <div class="col-12">
                        <div class="flex-row flex-center">
                            <router-link to="/itineraries" class="button primary-border">View all</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import ItineraryCard from '@/components/Itinerary/ItineraryCard.vue'
import {API, getItinerariesFeatured} from '@/api.js'
import ItineraryCardSkeleton from './ItineraryCardSkeleton.vue';
import { getConfig } from '@/data';
export default {
    props: {
        ItineraryList: Array,
    },
    name: 'ItinerariesList',
    data () {
        return {
            Itineraries: null,
            HeroTitle: '',
            HeroDescription: '',
            API: API
        }
    },
    async created () {
        let conf = await getConfig();
        if(conf.featureditineraries.data && conf.featureditineraries.data.length > 0){
            this.Itineraries = conf.featureditineraries.data;
        } else {
            this.Itineraries = this.ItineraryList ? this.ItineraryList : await getItinerariesFeatured();
        }

        this.HeroTitle = conf.Config.ITINERARIES_LIST 
            ? conf.Config.ITINERARIES_LIST.title ? conf.Config.ITINERARIES_LIST.title : '' : '';
        this.HeroDescription = conf.Config.ITINERARIES_LIST 
            ? conf.Config.ITINERARIES_LIST.description ? conf.Config.ITINERARIES_LIST.description : '' : '';
    },
    components: {
        ItineraryCard,
        ItineraryCardSkeleton
    }
}
</script>

<style lang="scss" scoped>
    .ItineraryList{
        &_title{
            display: flex;
            flex-direction: column;
            align-items: center;
            &-container{
                max-width: 400px;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
        &_empty{
            background: var(--color-bg-fade-light);
            padding: 50px 30px;
            z-index: 20;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
    }
</style>
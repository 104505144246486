<template>
    <section :id="sectionId" class="HeroBlock">
    <template v-if="MediaType == 'video'">
        <video :src="HeroMedia" autoplay muted loop class="HeroBlock_background"></video>
    </template>
    <template v-if="MediaType == 'image'">
        <img :src="HeroMedia" role="presentation" class="HeroBlock_background">
    </template>
    <template v-if="MediaType == 'slider'">
        <div class="HeroBlock_background">
            <Splide :options="splideOptions">
                <template v-for="(item, index) in this.HeroMedia" :key="index">
                    <SplideSlide><img :src="item" role="presentation"></SplideSlide>
                </template>
            </Splide>
        </div>
    </template>
        <div class="container">
            <div class="flex-col justify-center h-100 gap-20">
                <h1 class="baskerville text-5xl fw-200 HeroBlock_heading" v-if="HeroText" v-html="HeroText"></h1>
                <h1 class="baskerville text-5xl fw-200 HeroBlock_heading" v-else>Trips & activities <br> for adventurers travelers.</h1>
                <div>
                    <router-link to="/destinations" class="button w-fc primary-ghost">Our Destinations</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { API } from "@/api";
import { getConfig } from "@/data"
import { marked } from "marked";
import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
    methods: {
        MarkdownToHtml(md) {
            md = md.replaceAll('](/', `](${API.uploads}/`);
            return marked(md);
        },
        shuffle(array) {
            let currentIndex = array.length, randomIndex;
            // While there remain elements to shuffle.
            while (currentIndex != 0) {
                // Pick a remaining element.
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }
    },
    async created () {
        const LAST_SLIDER_KEY = 'slider_last';
        let conf = await getConfig();
        if(conf.HeroText){
            this.HeroText = conf.HeroText;
        }
        if(conf.HeroMedia.data && Array.isArray(conf.HeroMedia.data) && conf.HeroMedia.data.length > 0){
            if(conf.HeroMedia.data.length > 1){
                this.MediaType = 'slider';
                this.HeroMedia = [];
                let medias = [];
                conf.HeroMedia.data.forEach(media => {
                    if(media.attributes.mime.includes('image')){
                        medias.push(`${API.uploads}${media.attributes.url}`);
                    }
                })
                medias = this.shuffle(medias);
                if(localStorage.getItem(LAST_SLIDER_KEY)){
                    while(localStorage.getItem(LAST_SLIDER_KEY) == medias[0]){
                        medias = this.shuffle(medias);
                    }
                }
                localStorage.setItem(LAST_SLIDER_KEY, medias[0]);
                this.HeroMedia = medias;
            } else {
                if(conf.HeroMedia.data[0].attributes.mime.includes('image')){
                    this.MediaType = 'image';
                    this.HeroMedia = `${API.uploads}${conf.HeroMedia.data[0].attributes.url}`
                }
                if(conf.HeroMedia.data[0].attributes.mime.includes('video')) {
                    this.MediaType = 'video';
                    this.HeroMedia = `${API.uploads}${conf.HeroMedia.data[0].attributes.url}`
                }
            }
        }
    },
    props: {
        sectionId: String,
        cover: String,
        title: String,
    },
    data () {
        return {
            API: API,
            MediaType: 'video',
            HeroMedia: require('@/assets/hero_video.mp4'),
            HeroText: null,
            splideOptions: {
                type: 'loop',
                autoplay: true,
                arrows : false,
                interval: 5000
            }
        }
    },
    components: {
    Splide,
    SplideSlide,
}
    
}
</script>


<style lang="scss" scoped>

    .HeroBlock{
        min-height: fit-content;
        height: 90vh;
        position: relative;
        overflow: hidden;
        background: var(--color-background);
        isolation: isolate;
        flex-shrink: 0;
        &::after{
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(21deg, rgba(0,0,0,0.6321122198879552) 0%, rgba(0,0,0,0) 100%);
            z-index: -1;
        }
        &_heading{
            line-height: 3.6rem;
        }
        &_background{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -2;
            // opacity: 0.7;
        }
        .container{
            height: 100%;
            z-index: 2;
        }
        padding: 40px 20px;
        color: white;
        
    }
</style>
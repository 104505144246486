<template>
    <footer class="Footer">
        <div class="Footer_head">
            <img src="../assets/logo-sec-w.svg" alt="" class="Footer_logo">
        </div>
        <div class="Footer_body">
            <div class="container">
                <div class="grid align-v-center">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <div class="flex-row">
                            <ul class="FlistItems">
                                <span class="text-sm op-7 fw-300">&copy; MOROCCO TOURS DISCOVERY - 2022</span>
                                <template v-if="ContactInfo.tripadvisor">
                                    <li class="FItemIcon"><a :href="ContactInfo.tripadvisor.link" target="_blank"><i class='bx bxl-trip-advisor' ></i></a></li>
                                </template>
                                <template v-if="ContactInfo.instagram">
                                    <li class="FItemIcon"><a :href="ContactInfo.instagram.link" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                </template>
                                <template v-if="ContactInfo.facebook">
                                    <li class="FItemIcon"><a :href="ContactInfo.facebook.link" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                </template>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <ul class="FlistItems">
                            <router-link to="/"><li>Home</li></router-link>
                            <router-link to="/faq"><li>FAQ</li></router-link>
                            <router-link to="/aboutus"><li>About us</li></router-link>
                            <router-link to="/terms"><li>Terms</li></router-link>
                        </ul>
                    </div>
                </div>
                <div v-if="paypalLink" class="flex-row justify-end pt-3">
                    <div>
                        <!-- PayPal Logo --><table border="0" cellpadding="10" cellspacing="0" align="center"><tr><td align="center"></td></tr><tr><td align="center"><a :href="paypalLink" target="__blank" title="PayPal"><img src="https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/C2/logos-buttons/optimize/44_Grey_PayPal_Pill_Button.png" alt="PayPal" /></a></td></tr></table><!-- PayPal Logo -->
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { getContactInfo } from "@/api";
import { getConfig } from '@/data';

export default {
    data () {
        return {
            ContactInfo: {},
            paypalLink: ''
        }
    },
    async created () {
        this.ContactInfo = await getContactInfo();
        let conf = await getConfig();
        this.paypalLink = conf.Config.PAYPAL_ME_LINK? conf.Config.PAYPAL_ME_LINK: '';
    },
}
</script>

<style lang="scss" scoped>
.Footer{
    display: flex;
    flex-direction: column;
    background: var(--color-background);
    color: white;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    &_head{
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    &_logo{
        height: 40px;

    }
    &_body{
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @media(max-width: 500px){
            padding-bottom: 100px;
        }
    }
}

.FlistItems{

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    justify-content: flex-end;
    list-style: none;
    @media(max-width: 600px){
        flex-grow: 1;
        justify-content: center;
    }
    li{
        font-weight: 300;
        opacity: 0.6;
        text-transform: uppercase;
        transition: 0.2s ease-in-out;
        font-size: 14px;
        &:hover{
            opacity: 1;
        }
    }
    
    .FItemIcon{
        font-size: 23px;
    }
}
</style>
<template>
    <router-link :to="`/destination/${name}`">
        <div :class="`DestinationCard ${large == 'true' ? 'large' : ''}`">
            <img class="DestinationCard_background" :src="image" :alt="alt"/>
            <div class="flex-col gap-10">
                <span class="DestinationCard_title text-3xl fw-300">{{name}}</span>
                <hr>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: {
        name: String,
        image: String,
        alt: String,
        large: String,
    },
}
</script>

<style lang="scss" scoped>
    .DestinationCard{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        aspect-ratio: 3 / 2.25;
        width: 500px;
        @media(max-width: 600px){
            width: 400px;
        }
        background: var(--color-background);
        color: white;
        padding: 30px;
        flex-shrink: 0;
        cursor: pointer;
        position: relative;
        isolation: isolate;
        overflow: hidden;
        &.large{
            width: 100%;
        }
        hr{
            border: none;
            border-bottom: 1px solid white;
            position: relative;
            width: 100%;
            transition: 0.6s ease-in-out;
        }
        &:hover{
            .DestinationCard_background{
                transform: scale(1.02);
            }
            hr{
                width: 0%;
                align-self: flex-end;
            }
        }
        &_title{
            font-family: 'Libre Baskerville', serif;
        }
        &_background{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -2;
            width: 100%;
            height: 100%;
            transition: 0.4s ease-in-out;
            object-fit: cover;
        }

        &::after{
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
            z-index: -1;
            pointer-events: none;
        }
    }
</style>
<template>
  <nav :class="'Navbar '+(position || '')+' '+(style ||'')" x-bind:class="hide ? 'shrink':''">
    <div class="container">
      <div class="Navbar_container">
        <router-link to="/">
          <div class="Navbar_logo">
            <div class="Navbar_logo-wrapper">
              <img src="../assets/logo-main-w.svg" />
            </div>
          </div>
        </router-link>
        <div></div>
        <ul class="Navbar_items">
          <template v-for="(item, index) in Items" :key="index">
            <router-link :to="item.href">
              <li class="Navbar_item baskerville">{{ item.label }}</li>
            </router-link>
          </template>
          <li>
            <button class="menuButton" x-on:click="navbarOpen = !navbarOpen">
              <span class="material-icons-outlined"> menu </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <nav class="SidebarNav" x-bind:class="navbarOpen ? 'open': ''">
    <div class="SidebarNav_backdrop" x-on:click="navbarOpen = false"></div>
    <div class="SidebarNav_drawer">
      <div class="SidebarNav_drawer-header">
        <button class="SidebarNav_drawer-closebtn" x-on:click="navbarOpen = false">
          <span class="material-icons-outlined">close</span>
        </button>
      </div>
      <div class="SidebarNav_drawer-body">
        <ul>
          <template v-for="(item, index) in Items" :key="index">
            <router-link :to="item.href">
              <li class="baskerville" x-on:click="navbarOpen = false">{{ item.label }}</li>
            </router-link>
          </template>
          <template v-for="(item, index) in DrawerItems" :key="index">
            <router-link :to="item.href">
              <li class="baskerville" x-on:click="navbarOpen = false">{{ item.label }}</li>
            </router-link>
          </template>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      Items: [
        {
          label: "Destinations",
          href: "/destinations",
        },
        {
          label: "Itineraries",
          href: "/itineraries",
        },
        {
          label: "About us",
          href: "/aboutus",
        },
      ],
      DrawerItems: [
        {
            label: 'Activities',
            href: '/activities',
        },
        {
          label: "Our Journal",
          href: "/blog",
        },
        {
          label: "Gallery",
          href: "/gallery",
        },
        {
          label: "FAQ",
          href: "/faq",
        },
        {
          label: "Contact Us",
          href: "/enquire?contact=contactonly",
        },
        {
          label: "Terms ",
          href: "/terms",
        },
      ],
    };
  },
  name: "NavbarUi",
  props: {
    activeTab: String,
    position: String,
    style: String,
  },
};
</script>

<style lang="scss">
.SidebarNav {
  position: fixed;
  z-index: 150;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  inset: 0;
  pointer-events: none;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  &_backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--sidenav-backdrop-bg);
    // backdrop-filter: blur(5px);
    // -webkit-backdrop-filter: blur(5px);
    opacity: 0;
    transition: 0.2s ease-in-out;
    visibility: hidden;
    pointer-events: auto;
  }
  &_drawer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 400px;
    background: var(--color-background-2);
    color: white;
    transform: translateX(100%);
    transition: 0.3s cubic-bezier(0.4, 0, 1, 1);
    pointer-events: auto;
    border-left: 1px solid rgba(0, 0, 0, 0.05);

    display: flex;
    flex-direction: column;
    &-header {
      padding: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      // justify-content: flex-end;
    }

    &-closebtn {
      background: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      cursor: pointer;
      width: 40px;
      height: 40px;
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
    &-body {
      padding: 30px;
      display: flex;
      flex-direction: column;
      overflow: auto;
      color: var(--color-bg-fade);
      ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
        list-style: none;
        li {
          font-size: 30px;
          font-weight: 300;
          transition: 0.2s ease-in-out;
          &:hover {
            color: var(--color-bg-fade-light);
          }
        }
      }
    }
  }

  &.open {
    .SidebarNav_backdrop {
      opacity: 1;
      visibility: visible;
    }
    .SidebarNav_drawer {
      transform: translateY(0);
    }
  }
}
.Navbar {
  background: var(--color-background);
  color: white;
  position: sticky;
  top: 0px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  z-index: 100;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.05));
  .container {
    width: 100%;
  }
  &.fixed {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
  }
  &.shrink {
    .Navbar_logo {
      gap: 5;
      &-wrapper {
        // visibility: visible;
        overflow: visible;
        transform: translateY(-40px);
        max-height: 0;
        opacity: 0.9;
      }
      img{
        height: 40px;
      }
    }
  }
  &_logo {
    background: var(--color-background-2);
    padding: 12px 15px;
    max-width: 200px;
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.05));
    min-height: 60px;
    img {
      display: block;
      width: 100%;
      height: 60px;
      transition: 0.5s linear;
    }
    &-wrapper {
      visibility: visible;
      max-height: 100px;
      transition: 0.5s ease-in-out;
      overflow: hidden;
    }
    &-text {
      font-size: 16px;
      text-transform: uppercase;
      * {
        font-family: "Libre Baskerville", serif;
      }
    }
  }
  &_container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
  }
  &_items {
    display: flex;
    flex-direction: row;
    list-style: none;
    gap: 23px;
    align-items: center;
    .Navbar_item {
      padding: 15px 0px;
      // text-transform: uppercase;
      font-weight: 300;
      font-size: 0.85rem;
      opacity: 0.75;
      transition: 0.2s ease-in-out;
      letter-spacing: 0.2px;
      cursor: pointer;
      @media (max-width: 768px) {
        display: none;
      }
      &:hover {
        opacity: 1;
      }
    }
    .menuButton {
      background: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      cursor: pointer;
      width: 40px;
      height: 40px;
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
</style>
<template>
    <div class="flex-col bg-white NotFound">
        <div class="container h-100">
            <div class="flex-col h-100 text-center justify-center align-center py-5">
                <h1 class="text-8xl mb-3 color-primary">404</h1>
                <h3 class="text-2xl mb-1 fw-600">Not Found</h3>
                <span class="op-5 mb-5">Soory, we were unable to find that page.</span>
                <router-link to="/"><button class="button primary">Back to Home</button></router-link>
            </div>
        </div>
    </div>
    <FooterUi/>
</template>

<script>
import FooterUi from "@/components/FooterUi.vue";
import { appendMeta } from '../data';
export default {
    data () {
        return {
            seo: [{title: 'Page not found | Morocco Tours Discovery'}]
        }
    },
    components: { FooterUi },
    created() {
        appendMeta(this.seo)
    }
}
</script>

<style lang="scss" scoped>
    .NotFound{
        height: 90vh;
        min-height: min-content;
        flex-shrink: 0;
        padding: 30px;
    }
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueSplide from '@splidejs/vue-splide';
import { createHead } from "@vueuse/head"

const head = createHead()

createApp(App)
.use(router)
.use(VueSplide)
.use(head)
.mount('#app')

<template>
    <div class="Loading" id="loading-wrapper" :class="{'hide': !isHidden}">
        <i class='bx bx-loader-alt bx-spin'></i>
        <span>Loading</span>
    </div>
</template>

<script>
export default {
  props: {
    isHidden: Boolean
  },

}
</script>

<style lang="scss" scoped>
    .Loading{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        z-index: 999;
        background: #221917;
        color: rgba(255, 255, 255, 0.514);
        font-weight: 200;
        font-size: 14px;
        i{
            font-size: 20px;
        }

        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;

        user-select: none;
        transition: 0.2s ease-in-out;

        &.hide{
            background: transparent;
            opacity: 0;
            visibility: hidden;
        }
    }
</style>
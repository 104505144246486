<template>
    <div :class="`ActivityCard skeleton-background ${large == 'true' ? 'large' : ''}`">
        <div class="flex-col gap-10">
            <span class="ActivityCard_title skeleton-text"></span>
            <hr>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        large: String,
    },
}
</script>

<style lang="scss" scoped>
    .ActivityCard{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        aspect-ratio: 3 / 2;
        width: 400px;
        color: white;
        padding: 30px;
        flex-shrink: 0;
        cursor: pointer;
        position: relative;
        isolation: isolate;
        overflow: hidden;
        &.large{
            width: 100%;
        }
        hr{
            border: none;
            border-bottom: 2px solid var(--skeleton-color-deg);
            position: relative;
            width: 100%;
            transition: 0.6s ease-in-out;
        }
        &:hover{
            .ActivityCard_background{
                transform: scale(1.02);
            }
            hr{
                width: 0%;
                align-self: flex-end;
            }
        }
        &_title{
            height: 25px;
            width: 150px;
        }
        &_background{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -2;
            width: 100%;
            height: 100%;
            transition: 0.4s ease-in-out;
            object-fit: cover;
        }

        &::after{
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%);
            z-index: -1;
            pointer-events: none;
        }
    }
</style>
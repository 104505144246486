import axios from 'axios';
import qs from 'qs';

const HOST_API = process.env.VUE_APP_HOST_API || 'http://localhost:1337';

export const API = {
    host: HOST_API+`/api/`,
    uploads: HOST_API,
}

export const getDestinations = async () => {
    let ModelName = 'destinations';
    const query = qs.stringify({
        populate: ['Image', 'seo', 'seo.meta'],
        pagination: {
            page: 1,
            pageSize: 500,
        },
    }, {encodeValuesOnly: true});

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;

    return dataList ? dataList : [];
}

export const getDestinationActivities = async (index) => {
    let ModelName = 'destinations';
    const query = qs.stringify({
        filters: {
            Name: {
                $in: index,
            },
        },
        pagination: {
            page: 1,
            pageSize: 500,
        },
        populate: ['activities', 'activities.Image', 'activities.destinations', 'seo', 'seo.meta'],
    }, {encodeValuesOnly: true});

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;

    return dataList ? dataList[0] : null;
}

export const getDestinationItineraries = async (index) => {
    let ModelName = 'destinations';
    const query = qs.stringify({
        filters: {
            Name: {
                $in: index,
            },
        },
        pagination: {
            page: 1,
            pageSize: 500,
        },
        populate: ['itineraries', 'itineraries.Image', 'itineraries.destinations', 'itineraries.Price','itineraries.Departure', 'itineraries.Return', 'seo', 'seo.meta'],
    }, {encodeValuesOnly: true});

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;

    return dataList ? dataList : [];
}

export const getDestination = async (index) => {
    let ModelName = 'destinations';
    const query = qs.stringify({
        filters: {
            Name: {
                $eq: index,
            }
        },
        pagination: {
            page: 1,
            pageSize: 500,
        },
        populate: ["Image","itineraries", "itineraries.Locations", "itineraries.Price", "itineraries.Image", "itineraries.Departure", "itineraries.Return", "itineraries.destinations", 'seo', 'seo.meta'],
    })

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;

    return dataList ? dataList[0] : null;
}

export const getItineraries = async (all) => {
    let ModelName = 'itineraries';
    const query = qs.stringify({
        populate: '*',
        pagination: {
            page: 1,
            pageSize: all ? 1000 : 6,
        },
    }, {encodeValuesOnly: true});

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;

    return dataList ? dataList : [];
}

export const getItinerariesFeatured = async (all) => {
    let ModelName = 'itinerariesfeatured';
    const query = qs.stringify({
        populate: [
            'Locations',
            'Image',
            'destinations',
            'Price',
            'Departure',
            'Return',
            'seo'
        ],
        pagination: {
            page: 1,
            pageSize: all ? 1000 : 6,
        },
    }, {encodeValuesOnly: true});

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;

    return dataList ? dataList : [];
}

export const getRelativeItineraries = async (slug, tags) => {
    let ModelName = 'itineraries';
    let conditions = []
    if(tags) {
        tags.forEach(element => {
            conditions.push({
                Tags: {
                    $containsi: element
                }
            });
        });
    }
    const query = qs.stringify({
        populate: '*',
        filters: {
            $or: conditions,
            slug: {
                $ne: slug
            },
        },
        pagination: {
            pageSize: 6,
        },
    }, {encodeValuesOnly: true});

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;

    return dataList ? dataList : [];
}

export const getItinerary = async (index) => {
    let ModelName = 'itineraries';
    const query = qs.stringify({
        filters: {
            slug: {
              $eq: index,
            },
        },
        populate: ['*', 'Locations.Pictures', 'Price', 'Price.Includes', 'Price.Excludes', 'Image', 'Vehicle', 'Vehicle.Picture', 'Vehicle.Icon', 'destinations', 'feedbacks', 'feedbacks.itinerary', 'seo', 'seo.meta'],
    }, {encodeValuesOnly: true});

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;

    return dataList ? dataList[0] : null;
}

export const getActivities = async (count) => {
    let ModelName = 'activities';
    const query = qs.stringify({
        populate: '*',
        pagination: {
            page: 1,
            pageSize: count ?? 500,
        },
    }, {encodeValuesOnly: true});

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;

    return dataList ? dataList : [];
}

export const getActivity = async (index) => {
    let ModelName = 'activities';
    const query = qs.stringify({
        filters: {
            slug: {
                $eq: index,
            }
        },
        pagination: {
            page: 1,
            pageSize: 500,
        },
        populate: ["*", "Image", "destinations", "tags", 'seo', 'seo.meta'],
    })

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;
    
    return dataList ? dataList[0] : null;
}

export const getArticles = async (size) => {
    let ModelName = 'articles';
    const query = qs.stringify({
        populate: ['*', 'Picture', 'seo', 'seo.meta'],
        pagination: {
            page: 1,
            pageSize: size ? size : 500,
        },
    }, {encodeValuesOnly: true});

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;

    return dataList ? dataList : [];
}

export const getArticle = async (index) => {
    let ModelName = 'articles';
    const query = qs.stringify({
        filters: {
            slug: {
                $eq: index,
            }
        },
        pagination: {
            page: 1,
            pageSize: 500,
        },
        populate: ["*", "Picture", 'seo', 'seo.meta'],
    })

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;
    
    return dataList ? dataList[0] : null;
}

export const getFeedbacks = async (page) => {
    let ModelName = 'feedbacks';
    const query = qs.stringify({
        populate: ['*', 'itinerary'],
        pagination: {
            page: page || 1,
            pageSize: 2,
        },
    }, {encodeValuesOnly: true});

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;

    return dataList ? dataList : [];
}

export const getWhyUs = async () => {
    let ModelName = 'why-us';
    const query = qs.stringify({
        populate: ['*', 'Slides.Image', 'seo', 'seo.meta'],
    }, {encodeValuesOnly: true});

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;

    return dataList ? dataList : {attributes:null};
}

export const getAboutUs = async () => {
    let ModelName = 'about-us';
    const query = qs.stringify({
        populate: ['*', 'Slides.Image', 'HeroImage', 'seo', 'seo.meta'],
    }, {encodeValuesOnly: true});

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;

    return dataList ? dataList : {attributes:null};
}

export const getFaq = async () => {
    let ModelName = 'faq';
    const query = qs.stringify({
        populate: ['*', 'Questions', 'seo', 'seo.meta'],
    }, {encodeValuesOnly: true});

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;

    return dataList ? dataList : {attributes:null};
}

export const getGallery = async () => {
    let ModelName = 'gallery';
    const query = qs.stringify({
        populate: ['*', 'Medias', 'Medias.Media', 'seo', 'seo.meta'],
    }, {encodeValuesOnly: true});

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;

    return dataList ? dataList : {attributes:null};
}

export const getConfiguration = async () => {
    let ModelName = 'configuration';
    const query = qs.stringify({
        populate: ['*', 'Pages', 'Pages.Picture', 'featuredarticles', 'HeroMedia', 'featuredarticles.Picture', 
        'featureditineraries',
        'featureditineraries.Departure',
        'featureditineraries.Return', 
        'featureditineraries.destinations', 
        'featureditineraries.Image', 
        'featureditineraries.Price', 
        'seo', 'seo.meta'
    ],
    }, {encodeValuesOnly: true});

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;
    
    return dataList ? dataList : {attributes:null};
}

export const getContactInfo = async () => {
    let ModelName = 'contact';
    const query = qs.stringify({
        populate: ['*', 'socials', 'seo', 'seo.meta'],
    }, {encodeValuesOnly: true});

    let res = await axios.get(`${API.host}${ModelName}?${query}`);
    let dataList = res.data.data;
    let ContactInfo = dataList;
    if(ContactInfo && ContactInfo.attributes){
        ContactInfo = ContactInfo.attributes;
        if(Array.isArray(ContactInfo.socials))
            ContactInfo.socials.forEach(el => {
                ContactInfo[el.SocialMedia] = {
                    link : el.link,
                    username: el.username
                }
            })
    }
    return ContactInfo ? ContactInfo : {attributes:null};
}

<template>
    <router-link :to="`/article/${slug}`">
        <div :class="`ArticleCard ${cardStyle == 'minimal' ? 'minimal' : ''}`">
            <img class="ArticleCard_background" :src="image" :alt="imageAlt"/>
            <div class="ArticleCard_card">
                <span class="ArticleCard_title text-3xl fw-300 baskerville">{{name}}</span>
                <span class="text-sm fw-300 op-8 mb-3">{{moment(created_at).format('LL')}}</span>
                <div class="flex-row" v-if="cardStyle != 'minimal'">
                    <button class="button primary">View</button>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import moment from "moment";

export default {
    methods: {
    },
    setup() {
        return {moment}
    },
    props: {
        slug: String,
        name: String,
        image: String,
        cardStyle: String,
        created_at: String,
        imageAlt: String,
    },
}
</script>

<style lang="scss" scoped>
    .ArticleCard{
        display: flex;
        flex-direction: column;
        width: 100%;
        aspect-ratio: 5 / 3.5;
        position: relative;
        isolation: isolate;

        @media(max-width: 500px){
            aspect-ratio: unset;
        }

        &.minimal{
            aspect-ratio: unset;
            .ArticleCard_background{
                position: unset;
                aspect-ratio: 5 / 3.5;
            }
            .ArticleCard_card{
                position: unset;
                max-width: unset;
                width: 100%;
                background: transparent;
                padding: 20px 0px 0px 0px;
                gap: 10px;
            }
        }

        &_background{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media(max-width: 500px){
                position: unset;
                aspect-ratio: 5 / 3.5;
            }
        }
        &_card{
            background: var(--color-bg-fade-light);
            color: var(--color-background);
            filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
            bottom: -20px;
            left: 20px;
            position: absolute;
            z-index: 2;
            padding: 20px;
            width: 90%;
            gap: 15px;
            display: flex;
            flex-direction: column;
            max-width: 300px;
            transition: 0.2s ease-in-out;
            @media(max-width: 500px){
                position: unset;
                max-width: unset;
                width: 100%;
            }
        }
    }
</style>
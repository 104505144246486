
<template>
    <router-link :to="redirectTo">
        <div class="ItineraryCard">
            <img class="ItineraryCard_background" 
                :src="cover || '/assets/itinerary_placeholder.png'" 
                :alt="alt"/>
            <template v-if="locations.length > 0">
                <span class="ItineraryCard_location">
                    <template v-for="(item, index) in locations" :key="index">
                        <span class="text-xs">{{index > 0 ? '-':''}} {{item}} </span>
                    </template>
                </span>
            </template>
            <div class="flex-col gap-10">
                <span class="ItineraryCard_title text-3xl fw-300">{{title}}</span>
                <hr>
                <div class="flex-col">
                    <div class="ItineraryCard_description">
                        <span>{{days}} {{parseInt(days) < 2 ? 'Day' : 'Days' }}</span>
                        <template v-if="price > 0">
                            <span>From {{'$'+price}} <small>/person</small></span>
                        </template>
                    </div>
                    <template v-if="departure">
                        <div class="ItineraryCard_time">
                            <span class="ItineraryCard_time-wrapper"><span class="material-icons-outlined">start</span>
                                {{departure}}</span>
                            <span class="ItineraryCard_time-wrapper"><span class="material-icons-outlined">flag</span>
                                {{returnlocation}}</span>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'ItineraryCard',
    props: {
        title: String,
        redirectTo: String,
        cover: String,
        alt: String,
        locations: Array,
        days: Number,
        price: Number,
        departure: String,
        returnlocation: String,
    },
    async created() {
        
    },
}
</script>

<style lang="scss" scoped>
.ItineraryCard {
    width: 100%;
    aspect-ratio: 2.6 / 4;
    background: var(--color-background);
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: white;
    isolation: isolate;
    overflow: hidden;
    position: relative;
    transition: 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        .ItineraryCard_background {
            transform: scale(1.02);
        }
        hr {
            width: 0%;
            align-self: flex-end;
        }
        .ItineraryCard_time {
            max-height: 100px;
            opacity: 1;
            margin-top: 10px;
        }
    }

    &_location {
        position: absolute;
        top: 30px;
        left: 30px;
        z-index: 2;
        text-transform: uppercase;
        background: var(--color-primary);
        color: white;
        padding: 5px 10px;
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 1px;
        max-width: calc(100% - 60px);
    }

    &_background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -2;
        width: 100%;
        height: 100%;
        transition: 0.4s ease-in-out;
        object-fit: cover;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.75) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        z-index: -1;
        pointer-events: none;
    }

    &_title {
        font-family: "Libre Baskerville", serif;
    }
    hr {
        border: none;
        border-bottom: 1px solid white;
        position: relative;
        width: 100%;
        transition: 0.6s ease-in-out;
    }
    &_description {
        display: flex;
        flex-direction: row;
        gap: 20px;
        font-size: 15px;
        text-transform: uppercase;
        * {
            font-family: "Inter", sans-serif;
        }
    }
    &_time {
        display: flex;
        flex-direction: row;
        gap: 20px;
        font-size: 12px;
        text-transform: uppercase;
        max-height: 0px;
        // overflow: hidden;
        transition: 0.4s ease-in-out;
        opacity: 0;
        &-wrapper {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
        }
    }
}
</style>
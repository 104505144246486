import { getConfiguration, getContactInfo } from "./api";
import { useHead } from "@unhead/vue";

let appConfig = {
}
let appContacts = {
}

///

export const setConfig = (conf) => {
    appConfig = conf;
    appConfig.PagesConfig = {};
    if(conf && conf.Pages){
        conf.Pages.forEach(element => {
            appConfig.PagesConfig[element.Name] = element
        });
    }
    window.appConfig = appConfig
}
export const addToConfig = (data) => {
    appConfig = {
        ...appConfig,
        ...data
    };
}
export const getConfig = async () => {
    if(Object.entries(appConfig).length == 0) {
        let res = await getConfiguration();
        setConfig(res.attributes);
    }
    return appConfig;
}
export const getAttrConfig = (attr) => {
    return appConfig[attr];
}

///

export const setContacts = (conf) => {
    appContacts = conf;
}
export const addContacts = (data) => {
    appContacts = {
        ...appContacts,
        ...data
    };
}
export const getContacts = async () => {
    if(Object.entries(appContacts).length == 0) {
        appContacts = await getContactInfo();
    }
    return appContacts;
}
export const getAttrContacts = (attr) => {
    return appContacts[attr];
}

export const appendMeta = (seo) => {
    if(!Array.isArray(seo))
        return;
    let obj = {};
    for(let i=0; i<seo.length; i++) {
        let item = seo[i];
        if(!item)
            continue;
        if(item.description) {
            obj.meta = [];
            obj.meta.push({name:'description', content: item.description});
        }
        if(item.title)
            obj.title = item.title;
        useHead(obj);
        if(Array.isArray(item.meta)) {
            let metas = item.meta.filter(item=>item.name&&item.content).map(x=>({name:x.name, content:x.content}));
            for(let j=0; j<metas.length; j++) {
                let tag = document.createElement('meta');
                tag.setAttribute('name', metas[j].name);
                tag.setAttribute('content', metas[j].content);
                document.head.appendChild(tag);
            }
        }
    }
}

export const clearMeta = (arr) => {
    if(!Array.isArray(arr))
        return;
    for(let i=0; i<arr.length; i++) {
        if(!arr[i])
            continue;
        if(arr[i].meta) {
            let metas = arr[i].meta;
            for(let j=0; j<metas.length; j++) {
              if(!metas[j].name)
                continue;
              let el = document.querySelector("meta[name='"+metas[j].name+"']");
              if(el && el.parentNode)
                el.parentNode.removeChild(el);
            }
        }
        if(arr[i].link) {
            let links = arr[i].link;
            for(let j=0; j<links.length; j++) {
              if(!links[j].content)
                continue;
              let el = document.querySelector("meta[content='"+links[j].content+"']");
              if(el && el.parentNode)
                el.parentNode.removeChild(el);
            }
        }
    }
}
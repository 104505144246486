import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/destinations',
    name: 'destinations',
    component: () => import('../views/DestinationsView.vue')

  },
  {
    path: '/destination/:slug',
    name: 'destination',
    component: () => import('../views/DestinationView.vue')
  },
  {
    path: '/activities',
    name: 'activities',
    component: () => import('../views/ActivitiesView.vue')
  },
  {
    path: '/activity/:slug',
    name: 'activity',
    component: () => import('../views/ActivityView.vue')
  },

  {
    path: '/itineraries',
    name: 'itineraries',
    component: () => import('../views/ItinerariesView.vue')
  },
  {
    path: '/itinerary/:slug',
    name: 'itinerary',
    component: () => import('../views/ItineraryView.vue')
  },
  {
    path: '/aboutus',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import('../views/GalleryView.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/FaqView.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/TermsView.vue')
  },
  {
    path: '/enquire',
    name: 'enquire',
    component: () => import('../views/EnquireView.vue'),
  },
  {
    path: '/sendfeedback',
    name: 'feedback',
    component: () => import('../views/FeedbackView.vue'),
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/BlogView.vue'),
  },
  {
    path: '/article/:slug',
    name: 'article',
    component: () => import('../views/ArticleView.vue'),
  },
  { 
    path: '/404',
    component: NotFound 
  },
  { 
    path: '/:catchAll(.*)', 
    redirect: '404' 
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.querySelector('.app').scrollTop = 0;
    return { }
  },
})

export default router
